<template>
  <div>
    <md-button
      class="md-raised md-icon-button mg-left-0"
      :to="{ name: 'Article' }"
    >
      <font-awesome-icon icon="arrow-left" />
    </md-button>
    <article-form @onSubmit="onSubmit" :disabled="submitting" />
  </div>
</template>

<script>
import { Article } from "../../common/api/api.service";
import ArticleForm from "../../components/article/ArticleForm.vue";
import { ALERT, TOOLBAR_TITLE } from "../../store/actions.types";
export default {
  components: { ArticleForm },
  name: "ArticleCreate",
  data() {
    return {
      submitting: false,
    };
  },
  mounted() {
    this.$store.dispatch(TOOLBAR_TITLE, "article_create");
  },

  methods: {
    onSubmit(article) {
      this.submitting = true;
      Article.post(article)
        .then(() => {
          this.$router.push({ name: "Article" });
        })
        .catch(() => {
          this.$store.dispatch(ALERT, { message: "error" });
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
};
</script>
